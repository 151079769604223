/*

  Basic styles that set the visibility of tags and all tags in general.
  No classnames or IDs should be placed in this file and in general,
  the default visual representation of tags should be set.

*/

html {
    min-height: 100vh;
    font-size: $base-font-size;
    line-height: 1.5;
    font-family: $base-font-face, sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  
  body {
    margin: 0;
  }
  
  * {
    margin-top: 0;
    box-sizing: border-box;
  }
  
  *:before, *:after {
    box-sizing: inherit;
  }
  
  a {
    // color: $color-yodel-green;
    outline: 0;
    font-weight: bold;
  }
  
  h1, h2, h3, h4, h5, h6, b, strong {
    font-family: $base-font-face;
  }
  
  img {
    max-width: 100%;
  }
  
  svg {
    width: 100%;
  }
  
  /* 
    For the CIVIC cookie consent view:
    The svg width 100% we have as a base
    disrupts the styling for svgs in the
    cookie consent controller. This prevents
    that issue
  */
  #ccc svg {
    width: unset;
  }
  
  label {
    cursor: pointer;
  }
  
  input, textarea {
    font-family: $base-font-face, "Arial";
  }
  
  /* See https://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links */
  :focus { outline:none; }
  ::-moz-focus-inner { border: 0; }

  .regular-button {
    background-color: $color-sky-blue;
    color: $color-black;
    text-decoration: none;
    padding: 20px 25px;
    transition: 0.2s;

    &:hover {
      background-color: $secondary-color;
      color: $color-white;
    }

    &.-secondary-style {
      background-color: $secondary-color;
      color: $color-white;

      &:hover {
        background-color: $color-sky-blue;
        color: $color-black;
      }
    }

    &.-short {
      padding: 15px 25px;
    }
  }

  .branded-form {
    display: flex;
    flex-direction: column;

    .branded-input-container {
      display: flex;
      flex-direction: column;
      @include push-down;

      input,
      textarea,
      select {
        background-color: $input-background;
        border: 1px solid $input-border-color;
        padding: 15px;
        font-size: $base-font-size;
      }

      textarea {
        min-height: 150px;
      }

      label {
        margin-bottom: 5px;
      }
    }

    .branded-radio-container {
      font-size: $base-font-size;
      width: 100%;
      display: flex;
      flex-direction: column;

      label,
      p {
        margin-bottom: 10px;
      }
    }

    .branded-form__submit {
      display: flex;
      @include push-down;

      .regular-button {
        min-width: 150px;
        font-size: $base-font-size;
      }
    }

    .branded-form__multiple-input-row {
      display: flex;
      .branded-input-container {
        @include grid-column(6);
        @include omega(2);
      }

      @include media-mobile-only {
        flex-direction: column;

        .branded-input-container {
          @include grid-column(12);
          @include omega(0);
        }
      }
    }

    .branded-form__single-row {
      display: flex;
      .branded-input-container {
        @include grid-column(12);
      }
    }

    &.-blocked {
      pointer-events: none;
      opacity: 0.3;
      transition: opacity 1s;
    }
  }

  .section-under-construction {
    padding-top: 90px;
    background-color: $color-light-brown;

    .under-construction-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
    }

    .under-construction-text {
      display: flex;
      @include push-down;
      
      h1 {
        margin-left: 20px;
      }
    }

    .under-construction-picture {
      max-width: 400px;
    }

    @include media-mobile-only {
      .under-construction-picture {
        max-width: 90%;
      }

      .under-construction-text {
        flex-direction: column;
        align-items: center;
        @include push-down;
        
        h1 {
          text-align: center;
        }
      }
    }
  }
  